<template>
  <div class="post container-fluid">
    <div class="row py-4 px-3">
      <div class="col" v-if="!edit">
        <div class="m-0 post-font" v-html="data.post"></div>
      </div>
      <div class="text-editor" v-if="edit">
        <TextEditorCard
          :value="data.post"
          @changed="modifiedPost"
          class="p-0"
        />
        <Button
          class="publish-button"
          :label="labels.send"
          variant="btn-purple"
          :styles="{ padding: '8px 15px', fontWeight: 700 }"
          :action="() => saveChanged()"
        />
      </div>
    </div>
    <div class="row justify-content-between border-top border-bottom py-3 px-3">
      <div>
        <!--<img class="profile" :src="data.image" height="24" width="24" alt="image" />-->
        <span class="name px-3 border-right">{{ data.name }}</span>
        <!-- <span class="title px-3">{{ data.label }}</span> -->
        <span class="date pr-3 px-3 border-right">{{ data.date }}</span>
        <span class="name px-3">{{ data.time }}</span>
      </div>
      <div>
        <!-- <span class="link-icon pr-3 border-right">
                    <font-awesome-icon class="falink" icon="link"></font-awesome-icon>
                </span> -->
        <span v-if="userid == data.user" class="link-icon pr-3 border-right">
          <font-awesome-icon
            class="fa-pen"
            icon="pen"
            @click="editPost"
          ></font-awesome-icon>
        </span>
        <span v-if="userid == data.user" class="trash-icon pl-3">
          <font-awesome-icon
            class="fatrash"
            icon="trash-alt"
            @click="deleteNote"
          ></font-awesome-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextEditorCard from "@/components/Input/TextEditorCard";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from "@/components/Buttons/Button";
import {
  faLink,
  faTrashAlt,
  faPen,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
library.add(faTrashAlt, faLink, faPen, faPaperPlane);

export default {
  name: "AuthorsPost",
  components: { TextEditorCard, Button },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    action: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      edit: false,
      changedPost: "",
    };
  },
  computed: {
    ...mapState("_module", {
      singleData: "singleData",
    }),
    ...mapState("user", ["userid"]),
  },
  methods: {
    editPost() {
      this.edit = !this.edit;
      this.$emit("editPostStatus", this.edit);
    },
    modifiedPost(data) {
      this.changedPost = data.value;
    },
    saveChanged() {
      this.editPost();

      if (!this.data.post?.length) return;

      this.data.post = this.changedPost;
      this.$emit("updatedPost", this.data);
    },
    deleteNote() {
      this.$emit("removeNote", this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.post {
  background: $white;
  box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);

  .link-icon {
    margin-left: 6rem;
  }

  .post-font :last-child {
    margin-bottom: 0;
  }

  .trash-icon,
  .link-icon {
    color: gray;
    cursor: pointer;
  }
}

::v-deep {
  .text-editor {
    min-width: 100%;
  }
  .text-editor-card {
    box-shadow: none !important;
  }

  .post-font {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Oswald", sans-serif;
    }
  }
}

.text-editor {
  position: relative;
  .publish-button {
    right: 0;
    bottom: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    cursor: pointer;
  }
}
</style>
