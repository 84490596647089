<template>
    <div class="author container-fluid border-bottom">
        <div class="row">
            <div v-if="data.image" class="author-image col-auto">
                <img class="profile" :src="data.image" height="48" width="48" alt="image" />
            </div>
            <div class="author-description col">
                <span class="name">{{ data.username }}</span>
                <hr class="my-1" />
                <span class="description">{{ data._id }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faUserCircle);

export default {
    name: "AuthorImageCard",
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
};
</script>
<style lang="scss" scoped>
.author {
    background: $white;
    padding: 1.5rem 1rem;
    box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);

    &-image {
        .profile {
            border-radius: 50%;
        }
    }

    &-description {
        .name {
            font-weight: 700;
        }
    }
}
</style>
