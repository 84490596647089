<template>
  <div class="authors">
    <ContentContainer :order="{ body: 1, sidebar: 2 }">
      <template slot="body">
        <div class="search p-4 border-bottom mt-4">
          <FormulateInput
            v-if="posts.length"
            class="search-input pl-2"
            type="search"
            name="search"
            id="search"
            :placeholder="labels.write_here_to_search"
            v-model="searchText"
          />

          <div v-else class="search-no-text">
            {{ labels.no_comments_so_far }}
          </div>
        </div>
        <AuthorPost
          v-for="(item, index) in posts"
          :key="index"
          :data="item"
          @updatedPost="takeUpdatePost"
          @removeNote="deleteNoteBD"
          class="mt-4"
        />
        <div class="text-editor mt-4">
          <TextEditorCard
            v-if="isAuthor"
            @changed="takeANote"
            :update-key="updateKey"
          />

          <Button
            v-if="isAuthor"
            class="publish-button"
            :label="labels.send"
            variant="btn-purple"
            :styles="{ padding: '8px 15px', fontWeight: 700 }"
            :action="() => addNoteDB()"
          />
        </div>
      </template>
      <template slot="sidebar">
        <div class="author-image-card mt-4">
          <AuthorImageCard
            v-for="(item, index) in singleData.authors"
            :key="index"
            :data="item"
          />
        </div>
        <div
          class="author-buttons d-flex flex-lg-column justify-content-center mt-4"
        >
          <Button
            :label="labels.request_author"
            variant="btn-purple"
            class="mr-2 mr-lg-0 mb-2"
            :disabled="!isAuthor"
            :style="!isAuthor ? { cursor: 'not-allowed' } : null"
            @click="requestAuthor"
          />
          <Button
            v-if="canAddAuthor"
            :label="labels.add_author"
            variant="btn-purple"
            class="mr-2 mr-lg-0 mb-2"
            @click="addAuthor"
          />
          <Button
            v-if="isSuper"
            :label="labels.add_reviewer"
            variant="btn-purple"
            class="mr-2 mr-lg-0 mb-2"
            @click="addReviewer"
          />
        </div>
      </template>
    </ContentContainer>
    <router-view
      :modulePageData="modulePageData"
      :requested-authors-message="requestedAuthorsMessage"
      name="request_author"
    />
    <router-view :modulePageData="modulePageData" name="add_author" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import ContentContainer from "@/components/Containers/ContentContainer";
import AuthorImageCard from "@/components/Author/AuthorImageCard";
import AuthorPost from "@/components/Author/AuthorPost";
import TextEditorCard from "@/components/Input/TextEditorCard";
import Button from "@/components/Buttons/Button.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
library.add(faPaperPlane);

export default {
  name: "Authors",
  components: {
    ContentContainer,
    AuthorImageCard,
    AuthorPost,
    TextEditorCard,
    Button,
  },
  props: {
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateKey: Date.now(),
      data: {
        id: this.id,
        content: "",
      },
      a: 1,
      posts: [],
      requestedAuthorsMessage: false,
      searchText: "",
    };
  },
  computed: {
    ...mapState("helper", {
      user_id: "user_id",
      admin_roles: "admin_roles",
    }),
    ...mapState("_module", {
      singleData: "singleData",
    }),
    ...mapGetters("helper", ["labels"]),
    isAuthor() {
      let flag = false;
      if (this.admin_roles && Array.isArray(this.admin_roles)) {
        if (this.admin_roles.includes("author")) {
          this.singleData.authors?.forEach((author) => {
            if (author._id === this.user_id) {
              flag = true;
            }
          });
        }
      }
      return flag;
    },
    isSuper() {
      if (this.admin_roles && Array.isArray(this.admin_roles)) return this.admin_roles.includes("super");
      else return false;
    },
    canAddAuthor() {
      if (this.admin_roles && Array.isArray(this.admin_roles)) {
        if (
          this.admin_roles.includes("super") ||
          this.admin_roles.includes("creator")
        )
          return true;
      }
      return false;
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions("_module", {
      addNote: "addNote",
      deleteNote: "deleteNote",
      updateNote: "updateNote",
      getRequestAuthorsMessage: "getRequestAuthorsMessage",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapMutations("_module", {
      setSingleDataNotes: "setSingleDataNotes",
    }),
    takeANote(data) {
      this.data.content = data.value;
    },
    takeUpdatePost(updatePost) {
      let a = {
        id: this.id,
        note_id: updatePost._id,
        text: updatePost.post,
      };
      this.updateNote(a)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    },
    addNoteDB() {
      let data = {
        text: this.data.content,
        id: this.id,
        user: this.id,
        created: new Date().toISOString(),
      };

      this.addNote(data)
        .then((res) => {
          if (res.success) {
            this.generateNotes();
          }
        })
        .catch((err) => console.log(err));

      this.updateKey = Date.now();
    },
    deleteNoteBD(deletedNote) {
      let data = {
        id: this.id,
        note_id: deletedNote._id,
        text: deletedNote.post,
      };
      this.deleteNote(data)
        .then((res) => {
          if (res.success) {
            this.generateNotes();
          }
        })
        .catch((err) => console.log(err));
    },
    noteAuthorName(user) {
      if (user.name?.first && user.name?.middle && user.name?.last)
        return `${user.name.first} ${user.name.middle} ${user.name.last}`;
      if (user.name?.first && user.name?.last)
        return `${user.name.first} ${user.name.last}`;

      if (user.username) return user.username;

      return this.labels.unknown;
    },
    generateNotes() {
      if (this.singleData.notes && Array.isArray(this.singleData.notes)) {
        this.posts = [];

        this.singleData.notes.forEach((note, index) => {
          this.posts.push({
            _id: note._id,
            image: note.user.avatar || "no-image",
            name: this.noteAuthorName(note.user),
            date: note.created?.slice(0, 10) || "",
            time: note.created?.slice(11, 16) || "",
            post: note.text,
            user: note?.user?._id,
            key: index,
          });
        });
      }
    },
    requestAuthor() {
      if (this.$route.name !== "request-author") {
        this.$router.push({
          name: "request-author",
          query: { page: this.$route.query.page },
        });
      }
    },
    addAuthor() {
      if (this.$route.name !== "add-author") {
        this.$router.push({
          name: "add-author",
          query: { page: this.$route.query.page },
        });
      }
    },
    addReviewer() {
      if (this.$route.name !== "add-reviewer") {
        this.$router.push({
          name: "add-reviewer",
          query: { page: this.$route.query.page },
        });
      }
    },
    getRequest() {
      this.getRequestAuthorsMessage({ id: this.$route.params.id })
        .then((res) => {
          if (res.success) {
            this.requestedAuthorsMessage = res.data;
          } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.generateNotes();
    this.getRequest();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.authors {
  padding-left: 30px;
  padding-right: 30px;
  background: $white;

  ::v-deep {
    .formulate-input {
      &-element::before {
        padding-left: 2.5rem !important;
      }

      #search {
        padding-left: 2.375rem;
      }
    }
  }

  .text-editor {
    position: relative;
    .publish-button {
      right: 0;
      bottom: 0;
      margin-right: 1rem;
      margin-bottom: 1rem;
      position: absolute;
      cursor: pointer;
    }
  }

  .search {
    box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);
  }
}
</style>
